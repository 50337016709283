import { PageLayout } from "@components/layouts/page-layout";
import { getServerCacheProductCategoryConfig } from "@lib/fetching/product-category-config";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import Head from "next/head";

export default function Custom404(props: {
  configMap: ProductCategoryConfigMap;
}) {
  const title = "Page Not Found";
  const description = "We could not find the requested page.";
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description}></meta>
      </Head>
      <PageLayout configMap={props.configMap} noFooter={true}>
        <div role="alert" className="flex flex-col items-center justify-center">
          <p className="text-2xl">
            <strong>We could not find the requested page</strong>
          </p>
          <p className="text-lg max-w-lg">
            PerfectRec could not find the requested page. Please check the URL
            and try again.
          </p>
        </div>
      </PageLayout>
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      configMap: await getServerCacheProductCategoryConfig(),
    },
  };
}
